import type { ReadChangelogType } from '@readme/api/src/mappings/changelog/types';
import type { CustomPageReadType } from '@readme/api/src/mappings/custompage/types';

import React from 'react';
import { useParams } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';
import useEventListener from '@core/hooks/useEventListener';
import useUniqueId from '@core/hooks/useUniqueId';
import type { SuperHubDocumentData, SuperHubGuideReferencePage } from '@core/store';
import { useSuperHubStore } from '@core/store';
import { isChangelog, isCustomPage } from '@core/store/SuperHub/Document/util';
import type { APIErrorType } from '@core/types';

import { useSuperHubContext } from '@routes/SuperHub/Layout/Context';
import { getDirtyValues } from '@routes/SuperHub/Settings/Form/Project/utils';
import type { SuperHubRouteParams } from '@routes/SuperHub/types';

import Notification, { notify } from '@ui/Notification';
import RouteChangePrompt from '@ui/RouteChangePrompt';

import {
  useEditorKeyContext,
  useEditorValueProxyContext,
  useFormConfirmationContext,
  useSuperHubEditorFormContext,
} from '../Context';
import { compareDocumentStateToRemote } from '../util';

import styles from './index.module.scss';
import useFormRecovery from './useFormRecovery';

/**
 * Renders the top-level `<form>` element and handles all form submission logic
 * and validation error handling.
 */
const FormRoot: React.FC = ({ children }) => {
  const bem = useClassy(styles, 'FormRoot');
  const uid = useUniqueId('FormRoot');

  const [
    routeSection,
    isCreateNewPage,
    createDocument,
    updateDocument,
    findSidebarCategoryByTitle,
    findSidebarPageBySlug,
  ] = useSuperHubStore(s => [
    s.routeSection,
    s.editor.isCreateNewPage,
    s.document.createDocument,
    s.document.updateDocument,
    s.sidebar.findSidebarCategoryByTitle,
    s.sidebar.findSidebarPageBySlug,
  ]);

  const { routeParentCategory, routeParentPage } = useParams<SuperHubRouteParams>();
  const { browserRouterHistory } = useSuperHubContext();

  const {
    handleSubmit,
    formState: { isDirty, defaultValues, dirtyFields },
    reset,
  } = useSuperHubEditorFormContext();
  const { editorValueProxy } = useEditorValueProxyContext();
  const confirm = useFormConfirmationContext();
  const { updateFormRecovery } = useFormRecovery();
  const { editorKey, refreshEditorKey } = useEditorKeyContext();

  /**
   * Handle document save errors from the API.
   */
  const handleSubmitError = <T extends APIErrorType>(error: T, data: Partial<SuperHubDocumentData>) => {
    // If there's no field error information, we can assume it's an error no user action can fix, such as a network
    // error or server issue. In this case, we'll throw a notification and store the form data for recovery.
    if (!!error && (!error.info || !error.info.errors?.length) && !error.info.title.match(/MDX validation/)) {
      notify(
        <Notification kind="error" theme="dark">
          There was an error saving your changes.
        </Notification>,
      );
      updateFormRecovery(data);
    }
  };

  const createChangelog = (data: ReadChangelogType['data']) => {
    if (!data.title) {
      throw new Error('Title field is missing but required');
    }

    return createDocument({
      ...data,
      slug: data.slug || undefined,
    });
  };

  const createCustomPage = (data: CustomPageReadType['data']) => {
    if (!data.title) {
      throw new Error('Title field is missing but required');
    }

    return createDocument({
      ...data,
      slug: data.slug || undefined,
    });
  };

  const createGuideReferencePage = (data: SuperHubGuideReferencePage) => {
    // Parent category is either defined by the route param or within our
    // existing form data when creating a new page from an existing one.
    const parentCategory = data.category ?? findSidebarCategoryByTitle(routeParentCategory || '');
    const parentPage = data.parent ?? findSidebarPageBySlug(routeParentPage || '');

    // Category and title are both required when creating a new page.
    if (!parentCategory || !data.title) {
      throw new Error('Missing required fields');
    }

    return createDocument({
      ...data,
      category: { uri: parentCategory.uri },
      slug: data.slug || undefined,
      ...(parentPage?.uri
        ? {
            parent: { uri: parentPage.uri },
          }
        : {}),
    });
  };

  /**
   * Handles form submits for creating a new page document.
   */
  const handleCreate = async (data: SuperHubDocumentData) => {
    const newDocument = isChangelog(data)
      ? await createChangelog(data)
      : isCustomPage(data)
        ? await createCustomPage(data)
        : await createGuideReferencePage(data);

    // Reset dirty state now that we're saved and navigate to editor.
    reset(newDocument);
    browserRouterHistory.replace(`/update/${routeSection}/${newDocument.slug}`);
  };

  /**
   * Handle form submits for an existing document that's been updated.
   */
  const handleUpdate = async (data: SuperHubDocumentData) => {
    const dirtyValues = getDirtyValues(dirtyFields, data);
    const updatedDocument = await updateDocument(dirtyValues);
    reset(updatedDocument);

    // If the slug has changed, redirect to the new slug.
    if (updatedDocument.slug !== defaultValues?.slug) {
      browserRouterHistory.replace(`/update/${routeSection}/${updatedDocument.slug}`);
    }
  };

  const onSubmit = handleSubmit(async data => {
    if (!isDirty) return;

    // We don't update the form field on every MarkdownEditor `onChange` event
    // b/c seralizing the editor value into a string is expensive and slow. So
    // we delay this action until form submission, either by clicking "Save" or
    // pressing CMD+S hotkey. When editing the MD body, the form field is
    // intentionally set to the `editorKey` uid to mark the form as dirty. Otherwise
    // for Raw or HTML mode, we trust the form field to hold the full value.
    if (data.content.body === editorKey) {
      data.content.body = editorValueProxy?.toString() || '';
    }

    try {
      if (isCreateNewPage) {
        await handleCreate(data);
      } else {
        const statusOnRemote = await compareDocumentStateToRemote(dirtyFields);

        // If the user is editing the page and the document has been changed on another session since the
        // user started editing, prompt the user to confirm that they want to overwrite the changes.
        if (statusOnRemote === 'changed') {
          await confirm({
            onConfirmAction: () => handleUpdate(data),
            bodyText: 'Changes were made in another session since you started editing.',
            cancelText: 'Go Back',
            confirmText: 'Overwrite Changes',
            headingText: 'Overwrite Changes',
          });
          return;
        }

        // If the user is editing the page and the document has been deleted on another session since the
        // user started editing, prompt the user to confirm that they want to save as a new page.
        if (statusOnRemote === 'not-found') {
          await confirm({
            onConfirmAction: () => handleCreate(data),
            bodyText:
              'This page has been deleted or its slug was changed in another session since you started editing.',
            cancelText: 'Go Back',
            confirmText: 'Save as New Page',
            headingText: 'Page Not Found',
          });
          return;
        }

        await handleUpdate(data);
      }
    } catch (error) {
      handleSubmitError(error, data);
    }
  });

  // Enable hotkey to trigger form submission.
  useEventListener('keydown', (event: KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 's') {
      event.preventDefault();
      if (isDirty) {
        onSubmit();
      }
    }
  });

  return (
    <form className={bem()} id={uid('form')} onSubmit={onSubmit}>
      {children}
      <RouteChangePrompt
        onConfirm={() => {
          // Reset the form to its default values when the user confirms they want to leave. This prevents
          // the user from seeing stale data when they return to the editor.
          reset();
          refreshEditorKey();
        }}
        showWhen={isDirty}
        theme="dark"
      />
    </form>
  );
};

export default FormRoot;

import React from 'react';

import type { RealtimePageProps } from '@routes/Reference/Realtime';
import Authentication from '@routes/Reference/Realtime/Authentication';
import GettingStarted from '@routes/Reference/Realtime/GettingStarted';
import MyRequests from '@routes/Reference/Realtime/MyRequests';

/**
 * This a near mirror of Realtime component, but specific to git-backed API Config pages in SuperHub
 */
const APIConfig = ({
  apiConfig,
  doc,
  hasOasDefinition,
  inputRef,
  myRequestsEnabled,
  oas,
  oasPublicUrl,
  oauth,
  onError,
  operation,
  operationParams,
  requestsEnabled,
  sidebar,
}: RealtimePageProps) => {
  if (!doc) return null;

  switch (apiConfig) {
    case 'authentication':
      if (!hasOasDefinition) return null;

      return (
        <Authentication
          doc={doc}
          inputRef={inputRef}
          oas={oas}
          oasPublicUrl={oasPublicUrl}
          oauth={oauth}
          onError={onError}
          operation={operation}
          requestsEnabled={requestsEnabled}
          sidebar={sidebar}
        />
      );
    case 'getting-started':
      if (!hasOasDefinition) return null;

      return (
        <GettingStarted
          doc={doc}
          inputRef={inputRef}
          myRequestsEnabled={myRequestsEnabled}
          oas={oas}
          oasPublicUrl={oasPublicUrl}
          oauth={oauth}
          onError={onError}
          operation={operation}
          operationParams={operationParams}
          requestsEnabled={requestsEnabled}
          sidebar={sidebar}
        />
      );
    case 'my-requests':
      if (!myRequestsEnabled) return null;
      return <MyRequests oas={oas} />;
    default:
      return null;
  }
};

export default APIConfig;

import React from 'react';

import useClassy from '@core/hooks/useClassy';
import { useAPIDesignerStore } from '@core/store';

import ParameterGroup from '@ui/APIDesigner/ParameterGroup';
import RequestBodyEditor from '@ui/APIDesigner/RequestBodyEditor';
import Flex from '@ui/Flex';
import Skeleton from '@ui/Skeleton';

import ParameterGroupSkeleton, { SKELETON_WIDTH } from './ParameterGroup/Skeleton';
import styles from './style.module.scss';

interface Props {
  /** Whether API Designer should be in disabled state (i.e. if certain conditions in the spec limit ability to manually edit) */
  disabled?: boolean;
}

const APIDesigner = ({ disabled }: Props) => {
  const bem = useClassy(styles, 'APIDesigner');

  const [apiObject, setApiObject, createOperation] = useAPIDesignerStore(s => [
    s.apiObject,
    s.setApiObject,
    s.createOperation,
  ]);

  if (disabled) {
    return (
      <Flex className={bem('&', '_disabled')} gap="md" layout="col">
        <Skeleton width={SKELETON_WIDTH} />
        <ParameterGroupSkeleton />
      </Flex>
    );
  }

  if (!apiObject || !apiObject.schema) {
    return null;
  }

  if (!apiObject.path || !apiObject.method) {
    createOperation();
    return null;
  }

  return (
    <Flex align="stretch" className={bem()} gap="0" layout="col" tag="section">
      <ParameterGroup api={apiObject} in="path" updateAPI={setApiObject} />
      <ParameterGroup api={apiObject} in="query" updateAPI={setApiObject} />
      <ParameterGroup api={apiObject} in="header" updateAPI={setApiObject} />
      <RequestBodyEditor api={apiObject} updateAPI={setApiObject} />
    </Flex>
  );
};

export default APIDesigner;

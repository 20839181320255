import rdmd from '@readme/markdown';
import PropTypes from 'prop-types';
import React from 'react';

import classy from '@core/utils/classy';

import Button from '@ui/Button';
import Title from '@ui/Title';

import { TutorialProp } from '../Modal/proptypes/tutorials';

import classes from './style.module.scss';

const syntaxHighlighter = typeof window !== 'undefined' ? require('@readme/syntax-highlighter/dist').default : () => {};

const TutorialHero = ({ hero, openTutorial }) => (
  <section className={classy(classes.TutorialHero, 'rm-RecipeHero')}>
    <div className={classes['TutorialHero-Col']}>
      <Title className={classes['TutorialHero-Col-Title']} level={1}>
        {hero.title}
      </Title>
      <span className={`rm-Markdown markdown-body ${classes['TutorialHero-Col-Description']}`}>
        {rdmd(hero.description)}
      </span>
      {!!hero.steps && hero.steps.length > 0 && (
        <React.Fragment>
          <div className={classes['TutorialHero-Col-Heading']}>IN THIS RECIPE</div>
          <ol className={classes['TutorialHero-Col-List']}>
            {hero.steps.slice(0, 3).map((step, i) => {
              return (
                <li key={i} className={classes['TutorialHero-Col-List-Item']}>
                  {step.title}
                </li>
              );
            })}
          </ol>
        </React.Fragment>
      )}
      {!!hero.steps && hero.steps.length > 3 && (
        <button className={classes['TutorialHero-Col-Ellipsis']} type="button">
          •••
          <div className={classes['TutorialHero-Col-Ellipsis-Popup']}>
            <ol className={[classes['TutorialHero-Col-List'], classes['TutorialHero-Col-List_ellipsis']].join(' ')}>
              {hero.steps.slice(3).map((step, i) => {
                return (
                  <li key={i} className={classes['TutorialHero-Col-List-Item']}>
                    {step.title}
                  </li>
                );
              })}
            </ol>
          </div>
        </button>
      )}
      <Button
        className={classy(classes['TutorialHero-Col-Button'], 'rm-RecipeHero-button')}
        onClick={() => openTutorial('View', hero)}
        size="lg"
      >
        {!!hero.emoji && <span className={classes['TutorialHero-Col-Button-Emoji']}>{hero.emoji}</span>}
        <span>Open Recipe</span>
      </Button>
    </div>
    <div className={classes['TutorialHero-Image']}>
      <div className={classes['TutorialHero-Image-TutorialEditor-Nav']}>
        {!!hero.snippet &&
          hero.snippet.codeOptions.map(({ name }) => (
            <Button
              key={name}
              bem={{ white_text: true }}
              className={classes['TutorialHero-Image-TutorialEditor-Nav-Button']}
            >
              {name}
            </Button>
          ))}
      </div>
      {/*
        CodeMirror has a textarea that needs a label. Technically…not more accessible this way, but it does pass WCAG tests.
        Supposedly this is resolved if we upgrade CodeMirror, but have not confirmed.
       */}
      <label>
        {syntaxHighlighter(
          hero.snippet ? hero.snippet.codeOptions[0].code : '',
          hero.snippet ? hero.snippet.codeOptions[0].highlightedSyntax : 'javascript',
          {
            dark: true,
            highlightMode: true,
            tokenizeVariables: true,
            foldGutter: true,
          },
          { className: 'CodeEditor-Input CodeEditor-Input_readonly' },
        )}
      </label>
    </div>
  </section>
);

TutorialHero.propTypes = {
  hero: TutorialProp.isRequired,
  openTutorial: PropTypes.func.isRequired,
};

export default TutorialHero;

import type { ApiDefinitionFormProps } from './Form';
import type { APIDefinitionsReadType, ReadAPIDefinitionCollectionType } from '@readme/api/src/mappings/apis/types';

import React, { useState } from 'react';

import useClassy from '@core/hooks/useClassy';
import { ConnectSuperHubApiDefinitions, useSuperHubStore } from '@core/store';

import Flex from '@ui/Flex';

import ApiDefinitionForm from './Form';
import styles from './index.module.scss';
import ApiDefinitionList from './List';

export interface ApiDefinitionProps {
  /**
   * Represents the initial definitions collection to use while initializing
   * connection to our API endpoint to then continually hydrate data.
   */
  apiDefinitions?: ReadAPIDefinitionCollectionType['data'];

  /**
   * Represents the initial definitions collection to use while initializing
   * connection to our API endpoint to then continually hydrate data.
   */
  document?: never;
}

/**
 * Entrypoint route component for managing API definitions.
 */
const Content: React.FC<ApiDefinitionProps> = () => {
  const bem = useClassy(styles, 'ApiDefinition');
  const [formAction, setFormAction] = useState<ApiDefinitionFormProps['action'] | null>(null);
  const [formDefinition, setFormDefinition] = useState<APIDefinitionsReadType | null>(null);
  const [apiDefinitionData, isLoading] = useSuperHubStore(s => [
    s.apiDefinitions.data || [],
    s.apiDefinitions.isLoading,
  ]);

  // TODO: this seems to be false on initial render and we have no data?
  // const isLoading = apiDefinition.isLoading;
  const isEmptyState = !isLoading && !apiDefinitionData.length;

  return (
    <Flex align="stretch" className={bem()} gap="md" justify="center" layout="col">
      {isEmptyState && !formAction ? (
        <ApiDefinitionForm action="setup" onCreate={() => setFormAction('create')} />
      ) : formAction ? (
        <ApiDefinitionForm
          action={formAction}
          definition={formDefinition}
          onCancel={() => setFormAction(null)}
          onCreate={() => setFormAction('create')}
        />
      ) : (
        <ApiDefinitionList
          className={bem('-list')}
          onCreate={() => {
            // The default create action is to import a new API definition.
            setFormAction('import');
            setFormDefinition(null);
          }}
          onEdit={definition => {
            setFormAction('update');
            setFormDefinition(definition);
          }}
          onReplace={definition => {
            setFormAction('replace');
            setFormDefinition(definition);
          }}
        />
      )}
    </Flex>
  );
};

function ApiDefinition(props: ApiDefinitionProps) {
  return (
    <ConnectSuperHubApiDefinitions initialDefinitions={props.apiDefinitions}>
      <Content />
    </ConnectSuperHubApiDefinitions>
  );
}

export default ApiDefinition;

import type { APIDefinitionsReadType } from '@readme/api/src/mappings/apis/types';

import React from 'react';

import useClassy from '@core/hooks/useClassy';
import { useSuperHubStore } from '@core/store';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Tooltip from '@ui/Tooltip';

import ApiDefinitionCreateOrUpdate from './CreateOrUpdate';
import ApiDefinitionImporter from './Importer';
import styles from './index.module.scss';
import OwlbertImage from './owlbert.png';

function OpenAPITooltip() {
  const bem = useClassy(styles, 'ApiDefinitionForm');
  return (
    <Tooltip
      arrow={false}
      content={
        <div className={bem('-tooltip-content')}>
          <p>An OpenAPI file defines the structure and behavior of your REST API.</p>
          <p>
            To learn more about what formats we support,{' '}
            <a href="https://docs.readme.com/main/docs/openapi" rel="noreferrer" target="_blank">
              Read our Docs <Icon name="arrow-up-right" />
            </a>
          </p>
        </div>
      }
      delay={[400, 200]}
      interactive
      offset={[0, 5]}
      placement="bottom"
    >
      <span className={bem('-tooltip')}>OpenAPI file</span>
    </Tooltip>
  );
}

export interface ApiDefinitionFormProps {
  /**
   * Indicates whether the form should be catered to a user who is setting up
   * API definitions for the first time vs adding a new one to an existng set.
   * `setup` - Setting up API definitions for the first time (this is the default).
   * `create` - Adding a new API definition for the manual API Designer.
   * `update` - Updating an existing API definition for the manual API Designer.
   * `import` - Adding a new API definition from a file.
   * `replace` - Replacing an existing API definition with a new file.
   */
  action?: 'create' | 'import' | 'replace' | 'setup' | 'update';

  /**
   * Enables the ability to create a new API definition with a manual editor.
   * Defaults to `false`.
   */
  allowManualEditor?: boolean;

  /**
   * The API definition to be used when updating an existing definition.
   */
  definition?: APIDefinitionsReadType | null;

  /**
   * When provided, renders a cancel button that invokes this callback. Use this
   * when the form needs a way to cancel the operation and navigate back to some
   * previous state.
   */
  onCancel?: () => void;
  onCreate?: () => void;
}

const formTitleText = {
  create: 'Add API Definition',
  import: 'Add API Definition',
  replace: 'Replace API Definition',
  setup: 'Set Up Your API',
  update: 'Edit API Definition',
} satisfies Record<NonNullable<ApiDefinitionFormProps['action']>, string>;

/**
 * Renders a form that can be used either for initial setup or adding additional
 * API definitions.
 */
function ApiDefinitionForm({ action = 'setup', definition, onCancel, onCreate }: ApiDefinitionFormProps) {
  const bem = useClassy(styles, 'ApiDefinitionForm');
  const isAPIDesigner = useSuperHubStore(s => s.isAPIDesigner);

  return (
    <Flex align="stretch" className={bem()} gap="sm" justify="center" layout="col">
      <header className={bem('-header')}>
        {action === 'setup' && (
          <img alt="owlbert juggling" className={bem('-header-image')} height="100" src={OwlbertImage} width="117" />
        )}
        <Flex align="center" className={bem('-header-title')} justify="center" tag="h3">
          {!!onCancel && (
            <Button
              aria-label="Cancel API definition"
              circular
              className={bem('-cancel-action')}
              kind="minimum"
              onClick={() => onCancel?.()}
              outline
              size="xs"
            >
              <Icon name="arrow-left" />
            </Button>
          )}
          <span>{formTitleText[action]}</span>
        </Flex>

        {action === 'replace' ? (
          <p className={bem('-header-description')}>
            Overwrite the contents of the <OpenAPITooltip /> for <strong>{definition?.filename}</strong>.
          </p>
        ) : action === 'setup' ? (
          <p className={bem('-header-description')}>
            ReadMe can generate your API Reference from an <OpenAPITooltip />.
          </p>
        ) : null}
      </header>

      {['create', 'update'].includes(action) && (
        <ApiDefinitionCreateOrUpdate
          definition={definition}
          onCancel={() => onCancel?.()}
          onSave={() => onCancel?.()}
        />
      )}

      {['setup', 'import', 'replace'].includes(action) && (
        <ApiDefinitionImporter
          className={bem('-importer')}
          filename={definition?.filename}
          onImport={() => onCancel?.()}
        />
      )}

      {!!isAPIDesigner && ['setup', 'import'].includes(action) && (
        <footer className={bem('-footer')}>
          <h4 className={bem('-footer-title')}>Don’t have an OpenAPI file?</h4>
          <p className={bem('-footer-description')}>You can create one using our manual editor.</p>
          <Button onClick={() => onCreate?.()} size="sm" text>
            <Icon name="tool" />
            Start From Scratch
          </Button>
        </footer>
      )}
    </Flex>
  );
}

export default ApiDefinitionForm;

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import useDebouncedLoadingState from '@core/hooks/useDebouncedLoadingState';
import useUniqueId from '@core/hooks/useUniqueId';
import type { ProjectState } from '@core/store';
import { useProjectStore } from '@core/store';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Icon from '@ui/Icon';
import Menu, { MenuItem } from '@ui/Menu';
import { RHFGroup } from '@ui/RHF';
import Toggle from '@ui/Toggle';

const APIDesignerSettingsDropdown = () => {
  const uid = useUniqueId('ApiDefinitionList');
  const [apiDesigner, save, isSaving] = useProjectStore(s => [s.data.api_designer, s.save, s.isSaving]);
  const {
    control,
    handleSubmit,
    formState: { dirtyFields, isSubmitting, isValidating },
    reset,
  } = useForm<ProjectState['api_designer']>({
    defaultValues: apiDesigner,
    mode: 'onChange',
  });
  const debouncedIsSaving = useDebouncedLoadingState(isSaving);

  const onSubmit = handleSubmit(async data => {
    await save({
      api_designer: {
        ...data,
      },
    });
    reset(data);
  });

  // Automatically save when the visibility changes
  useEffect(() => {
    if (dirtyFields.allow_editing && !isSubmitting && !isValidating) {
      onSubmit();
    }
  }, [dirtyFields, onSubmit, isSaving, isSubmitting, isValidating]);

  return (
    <Dropdown>
      <Button aria-label="Settings" kind="secondary" size="sm" text>
        <Icon name="settings" />
      </Button>
      <form onSubmit={onSubmit}>
        <Menu>
          <MenuItem description="Disable if you want to manage your OAS file outside of ReadMe" TagName="label">
            Allow Editing
            <RHFGroup control={control} id={uid('allow_editing')} name="allow_editing">
              {({ field }) => (
                <Toggle
                  {...field}
                  checked={field.value === 'enabled'}
                  disabled={debouncedIsSaving}
                  onChange={event => {
                    field.onChange(event.target.checked ? 'enabled' : 'disabled');
                  }}
                  type="toggle"
                />
              )}
            </RHFGroup>
          </MenuItem>
        </Menu>
      </form>
    </Dropdown>
  );
};

export default APIDesignerSettingsDropdown;

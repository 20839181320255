import React from 'react';

import { useProjectStore, useSuperHubStore } from '@core/store';

import PageError from '../../Layout/PageError';

export default function ValidationError() {
  const projectName = useProjectStore(s => s.data.name);
  const [renderable, mdxValidationError, documentData] = useSuperHubStore(s => [
    (s.document.getGuidesPageData() || s.document.getReferencePageData())?.renderable,
    s.document.mdxValidationError,
    s.document.data,
  ]);

  const hasMdxValidationError = !!mdxValidationError;
  const hasRenderError = !!renderable && renderable?.status === false && !!renderable?.message && !!documentData;

  return hasMdxValidationError ? (
    <PageError
      mailtoAddress="support+mdx@readme.com"
      mailtoSubject="MDX Validation Issue"
      message={mdxValidationError}
      subtitle="We found an error when attempting to save your document. Please correct the following issue and try again:"
      title="Validation Error"
    />
  ) : hasRenderError ? (
    <PageError
      mailtoBody={`We're having trouble fixing the page "${documentData.title}" after attempting to migrate the project "${projectName}" to MDX.`}
      mailtoSubject="MDX Migration Issue"
      message={renderable.message}
      subtitle="We found an error trying to render your document. Please correct the following issue:"
      title="Invalid MDX"
    />
  ) : null;
}

import { HTTP_METHOD, type HTTPMethod } from '@readme/iso';
import React from 'react';

import useClassy from '@core/hooks/useClassy';
import { useAPIDesignerStore } from '@core/store/APIDesigner';

import APIMethod from '@ui/API/Method';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';

import classes from './style.module.scss';

const httpMethods: HTTPMethod[] = [
  HTTP_METHOD.GET,
  HTTP_METHOD.POST,
  HTTP_METHOD.PUT,
  HTTP_METHOD.PATCH,
  HTTP_METHOD.DELETE,
  HTTP_METHOD.HEAD,
  HTTP_METHOD.TEST,
  HTTP_METHOD.TRACE,
];

const OperationEditor = () => {
  const [setMethod, setPath, path, method, schema] = useAPIDesignerStore(s => [
    s.setMethod,
    s.setPath,
    s.apiObject?.path,
    s.apiObject?.method,
    s.apiObject?.schema,
  ]);
  const bem = useClassy(classes, 'OperationEditor');

  if (!path || !method) {
    return null;
  }

  if (!schema || !schema.paths) {
    // There isn't anything in the OAS document yet
    // Do we need to handle this? How should we?
    return null;
  }

  const changeMethod = e => {
    const newMethod = e.target.value;
    setMethod(newMethod);
  };

  const changePath = e => {
    const newPath = e.target.value;
    setPath(newPath);
  };

  return (
    <Flex align="center" className={bem()} gap="xs" justify="start" tag="form">
      <label className={bem('-method')}>
        <APIMethod
          className={bem('-method-pill')}
          suffix={<Icon className={bem('-icon')} name="chevron-down" />}
          type={method as HTTPMethod}
        />
        <select className={bem('-method-select')} name="path_method" onChange={changeMethod} value={method}>
          {Object.values(httpMethods).map(m => {
            return (
              <option key={m} value={m}>
                {m.toUpperCase()}
              </option>
            );
          })}
        </select>
      </label>

      <Flex gap="0">
        <span>{schema.servers?.[0]?.url}</span>
        <input className={bem('-input')} onChange={changePath} value={path} />
      </Flex>
    </Flex>
  );
};

export default OperationEditor;

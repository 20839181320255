import type { EndpointDataType } from '@readme/api/src/mappings/page/reference/types';

import React, { useMemo } from 'react';

import useUniqueId from '@core/hooks/useUniqueId';
import { InitializeAPIDesignerStore, useSuperHubStore } from '@core/store';
import { ConnectFormToAPIDesignerStore } from '@core/store/APIDesigner/ConnectFormToAPIDesignerStore';

import APIDesigner from '@ui/APIDesigner';
import OperationEditor from '@ui/APIDesigner/OperationEditor';
import { RHFGroup } from '@ui/RHF';

import { useSuperHubEditorFormContext } from '../Context';
import Layout, { LayoutBody, LayoutHeader, LayoutTitle } from '../Layout';
import HeaderInput from '../shared/HeaderInput';
import MarkdownEditor from '../shared/MarkdownEditor';
import WhatsNextEditor from '../shared/WhatsNext';
import useFormSetFocus from '../useFormSetFocus';

import EndpointBanner from './EndpointBanner';
import EndpointLimitedNotification from './EndpointLimitedNotification';

/**
 * Form fields specific to the `endpoint` page type.
 */
export default function EndpointPage() {
  const uid = useUniqueId('SuperHubEditorFormEndpointPage');
  const [isAPIDesigner, isAPIDesignerLimited] = useSuperHubStore(s => [
    s.isAPIDesigner,
    s.document.apiDesignerLimited?.byFeature || s.document.apiDesignerLimited?.byProjectLevel,
  ]);
  const {
    control,
    formState: { defaultValues },
  } = useSuperHubEditorFormContext();
  const apiFormDefaults = defaultValues && 'api' in defaultValues ? defaultValues.api : undefined;

  const apiObject = useMemo(() => apiFormDefaults as EndpointDataType, [apiFormDefaults]);
  const apiSource = apiFormDefaults?.source as Exclude<NonNullable<typeof apiFormDefaults>['source'], undefined>;

  useFormSetFocus('title');

  return (
    <InitializeAPIDesignerStore apiObject={apiObject}>
      <ConnectFormToAPIDesignerStore>
        <Layout notification={!!isAPIDesignerLimited && <EndpointLimitedNotification />}>
          <LayoutHeader>
            <LayoutTitle>
              <RHFGroup control={control} id={uid('title')} name="title" required>
                {({ field }) => <HeaderInput {...field} disabled={!!isAPIDesignerLimited} />}
              </RHFGroup>
            </LayoutTitle>
            {!!isAPIDesigner && <OperationEditor />}
            <RHFGroup control={control} id={uid('content-excerpt')} name="content.excerpt">
              {({ field }) => <HeaderInput {...field} disabled={!!isAPIDesignerLimited} value={field.value || ''} />}
            </RHFGroup>
          </LayoutHeader>
          <LayoutBody>
            {!isAPIDesigner && <EndpointBanner source={apiSource} />}
            {isAPIDesigner ? (
              <>
                <MarkdownEditor />
                <APIDesigner disabled={!!isAPIDesignerLimited} />
              </>
            ) : (
              <MarkdownEditor />
            )}
            <WhatsNextEditor />
          </LayoutBody>
        </Layout>
      </ConnectFormToAPIDesignerStore>
    </InitializeAPIDesignerStore>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';
import { useSuperHubStore } from '@core/store';

import Flex from '@ui/Flex';
import Notification from '@ui/Notification';

import styles from './index.module.scss';

const EndpointLimitedNotification = () => {
  const bem = useClassy(styles, 'EndpointLimitedNotification');
  const [apiDesignerLimited] = useSuperHubStore(s => [s.document.apiDesignerLimited]);

  // If editing is disabled at the project level, show a different message
  const copy = apiDesignerLimited?.byProjectLevel
    ? 'Editing has been disabled at the project level. You can enable editing from the API Definitions page.'
    : 'This endpoint uses a feature that’s not currently supported. Continue editing by re-uploading or syncing your OAS file.';

  return (
    <Notification
      className={bem('&')}
      description={
        <Flex gap="xs" layout="col">
          <span>{copy}</span>

          <Link className={bem('-link')} to="/update/reference">
            API Definitions
          </Link>
        </Flex>
      }
      iconName="pen-off"
      kind="secondary"
      theme="dark"
      title="Limited Functionality"
    />
  );
};

export default EndpointLimitedNotification;

import type { APIDefinitionsReadType } from '@readme/api/src/mappings/apis/types';

import React from 'react';

import useClassy from '@core/hooks/useClassy';
import { useSuperHubStore } from '@core/store';

import Button from '@ui/Button';
import { useConfirmationDialog } from '@ui/ConfirmationDialog';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Menu, { MenuItem, MenuDivider } from '@ui/Menu';
import Spinner from '@ui/Spinner';
import Timestamp from '@ui/Timestamp';

import styles from './index.module.scss';
import ApiDefinitionStatusBadge from './StatusBadge';
import ApiDefinitionType from './Type';

const sourceMap: Record<APIDefinitionsReadType['source']['current'], string> = {
  api: 'API',
  apieditor: 'the API Editor',
  bidi: 'Git',
  form: 'File Upload',
  rdme: 'CLI',
  rdme_github: 'GitHub Actions',
  url: 'URL',
};

interface ApiDefinitionItemProps {
  definition: APIDefinitionsReadType;
  onEdit?: (definition: APIDefinitionsReadType) => void;
  onReplace?: (definition: APIDefinitionsReadType) => void;
}

const ApiDefinitionItem: React.FC<ApiDefinitionItemProps> = ({ definition, onReplace, onEdit }) => {
  const bem = useClassy(styles, 'ApiDefinitionListItem');
  const { confirm, ConfirmationDialog } = useConfirmationDialog();
  const deleteDefinition = useSuperHubStore(s => s.apiDefinitions.deleteDefinition);

  const isUpdating = ['pending', 'pending_update'].includes(definition.upload.status);
  const isErrored = ['failed', 'failed_update'].includes(definition.upload.status);

  return (
    <Flex align="stretch" className={bem('&')} gap="sm" layout="col" tag="li">
      <Flex align="center" gap="sm" justify="start">
        <Flex align="center" className={bem('&-label')} gap="md">
          {definition.filename}
          <ApiDefinitionType type={definition.type} />
        </Flex>
        <Flex align="center" className={bem('&-right')} gap="md">
          <Dropdown className={bem('-list-dropdown')}>
            <Button aria-label="More options" kind="secondary" size="sm" text>
              <Icon name="more-horizontal" />
            </Button>
            <Menu>
              <MenuItem onClick={() => onEdit?.(definition)}>
                <Icon aria-hidden="true" name="edit" />
                <span>Edit Definition</span>
              </MenuItem>
              <MenuItem href={`/openapi/${definition.filename}`} TagName="a" target="_blank">
                <Icon aria-hidden="true" name="oas" />
                <span>View OAS</span>
              </MenuItem>
              <MenuItem onClick={() => onReplace?.(definition)}>
                <Icon aria-hidden="true" name="repeat" />
                <span>Replace OAS</span>
              </MenuItem>
              <MenuDivider />
              <MenuItem
                color="red"
                onClick={async () => {
                  await confirm({
                    onConfirmAction: () => deleteDefinition(definition.filename),
                    bodyText: `This will delete all pages in your API Reference section for "${definition.filename}". If you have Developer Dashboard pages using an endpoint in this API, they will also be unpublished.`,
                    cancelText: 'Cancel',
                    confirmText: 'Permanently Delete',
                    headingText: 'Remove API Definition?',
                  });
                }}
              >
                <Icon aria-hidden="true" name="trash" />
                <span>Delete</span>
              </MenuItem>
            </Menu>
          </Dropdown>
          <ConfirmationDialog />
        </Flex>
      </Flex>

      <div className={bem('-status')}>
        <div className={bem('-status-info', isUpdating && '-status-info_loading')}>
          {isUpdating ? (
            <>
              <Spinner size="sm" />
              Updating…
            </>
          ) : (
            <>
              <span className={bem('-status-info-title')}>Last Updated</span>
              <Timestamp value={definition.updated_at} />
              <span>•</span>
              via {sourceMap[definition.source.current]}
              <ApiDefinitionStatusBadge status={definition.upload.status} />
            </>
          )}
        </div>
        {!!isErrored && !!definition.upload.reason && (
          <pre className={bem('-status-error')}>
            <code>{definition.upload.reason}</code>
          </pre>
        )}
      </div>
    </Flex>
  );
};

export default ApiDefinitionItem;

import React from 'react';

import useClassy from '@core/hooks/useClassy';

import styles from './index.module.scss';

interface LayoutBodyProps {
  children: React.ReactNode;
}

/**
 * Common layout component for the SuperHub editor form body.
 */
export default function LayoutBody({ children }: LayoutBodyProps) {
  const bem = useClassy(styles, 'SuperHubEditorFormLayoutBody');

  return <main className={bem()}>{children}</main>;
}

import React, { useMemo } from 'react';

import useClassy from '@core/hooks/useClassy';
import { useProjectStore, useSuperHubStore } from '@core/store';

import Alert from '@ui/Alert';

import classes from './index.module.scss';

interface PageErrorProps {
  mailtoAddress?: string;
  mailtoBody?: string;
  mailtoSubject?: string;
  message?: string | null;
  subtitle?: string;
  title: string;
}

/**
 * Used to render an error message in the editor form body when the page has an error.
 */
export default function PageError({
  mailtoAddress = 'support@readme.com',
  mailtoBody,
  mailtoSubject = 'ReadMe Editor Issue',
  message,
  title,
}: PageErrorProps) {
  const bem = useClassy(classes, 'PageError');
  const [documentSlug, documentTitle] = useSuperHubStore(s => [s.document.data?.slug, s.document.data?.title]);
  const [projectName, projectId] = useProjectStore(s => [s.data.name, s.data.id]);

  const helpMailtoBody =
    mailtoBody || `We're having trouble editing the page "${documentTitle}" in the "${projectName}" project.`;

  const subtitle = useMemo(
    () => (
      <>
        {!!message && (
          <pre className={bem('-message')}>
            <code>{message}</code>
          </pre>
        )}
        <span className={bem('-help')}>
          Need help?{' '}
          <a
            className={bem('-help-link')}
            href={`mailto:${mailtoAddress}?subject=${mailtoSubject} [page:${documentSlug}:${projectId}]&body=${helpMailtoBody}`}
            rel="noreferrer"
            target="_blank"
          >
            Ask support
          </a>
        </span>
      </>
    ),
    [bem, documentSlug, helpMailtoBody, mailtoAddress, mailtoSubject, message, projectId],
  );

  return <Alert subtitle={subtitle} title={title} type="danger" />;
}

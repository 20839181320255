import type { SecuritySchemesObject, SecuritySchemeObject } from 'oas/types';

import { useMemo } from 'react';

/**
 * Checks if a security scheme is a basic scheme.
 */
function isBasicScheme(scheme?: SecuritySchemeObject): scheme is SecuritySchemeObject & { scheme: 'basic' } {
  return scheme?.type === 'http' && scheme?.scheme === 'basic';
}

/**
 * Checks if a security scheme is a bearer scheme.
 */
function isBearerScheme(scheme?: SecuritySchemeObject): scheme is SecuritySchemeObject & { scheme: 'bearer' } {
  return scheme?.type === 'http' && scheme?.scheme === 'bearer';
}

/**
 * Checks if a security scheme is an API key scheme.
 */
function isApiKeyScheme(
  scheme?: SecuritySchemeObject,
): scheme is SecuritySchemeObject & { in: 'header' | 'query'; name: string } {
  return scheme?.type === 'apiKey';
}

/**
 * This hook provides the necessary logic to manage the "Authentication Type" selection when creating or updating an
 * API Definition for the API Designer.
 *
 * These fields modify the `components.securitySchemes` object which is a top-level object in the OpenAPI schema that
 * defines the security schemes. Each security scheme is defined by a key-value pair, where the key is an
 * arbitrary name of the security scheme and the value is an object that describes the security scheme.
 *
 * This hook abstracts the logic to handle the different security scheme types and their respective fields, while
 * retaining any pre-existing arbitrary names and the values for an OAS file the user has synced from outside
 * the API designer.
 *
 * @note We currently only support selecting one security scheme per API definition in the API Designer!
 */
export default function useAuthenticationTypeFields(securitySchemes: SecuritySchemesObject) {
  return useMemo(() => {
    const keys = securitySchemes ? Object.keys(securitySchemes) : [];
    const schemeKey = keys[0];
    const schemeValue = securitySchemes ? Object.values(securitySchemes)[0] : ({} as SecuritySchemeObject);

    return {
      /**
       * We currently only support one security scheme per API definition in the API Designer.
       * If there are multiple security schemes, we disable the ability to change the security schemes.
       */
      isSecuritySchemesDisabled: keys.length > 1,
      /**
       * Determines if the current security scheme is an API key scheme.
       */
      currentSecurityScheme: {
        isApiKey: isApiKeyScheme(schemeValue),
        key: schemeKey,
      },
      /**
       * Represents the radio button fields for selecting a security scheme.
       */
      securitySchemesFields: [
        {
          label: 'None',
          checked: JSON.stringify(securitySchemes) === '{}',
          getValue: () => ({}),
        },
        {
          label: 'API Key',
          checked: isApiKeyScheme(schemeValue),
          getValue: () => ({
            // If the current security scheme has an existing name, use it. Otherwise, use a default name.
            [isApiKeyScheme(schemeValue) ? schemeKey : 'ApiKeyAuth']: {
              // Default values for an API key scheme.
              type: 'apiKey',
              in: isApiKeyScheme(schemeValue) ? schemeValue.in : 'header', // Can be updated via other fields in the form.
              name: isApiKeyScheme(schemeValue) ? schemeValue.name : '', // Can be updated via other fields in the form.
            },
          }),
        },
        {
          label: 'Basic',
          checked: isBasicScheme(schemeValue),
          getValue: () => ({
            // If the current security scheme has an existing name, use it. Otherwise, use a default name.
            [isBasicScheme(schemeValue) ? schemeKey : 'BasicAuth']: {
              // Default values for a basic scheme.
              type: 'http',
              scheme: 'basic',
            },
          }),
        },
        {
          label: 'Bearer',
          checked: isBearerScheme(schemeValue),
          getValue: () => ({
            // If the current security scheme has an existing name, use it. Otherwise, use a default name.
            [isBearerScheme(schemeValue) ? schemeKey : 'BearerAuth']: {
              // Default values for a bearer scheme.
              type: 'http',
              scheme: 'bearer',
            },
          }),
        },
      ],
    };
  }, [securitySchemes]);
}

import type { SchemaFormat, SchemaType } from '../TypeMenu';
import type { EndpointDataType } from '@readme/api/src/mappings/page/reference/types';
import type { RequestBodyObject, SchemaObject } from 'oas/types';

import produce from 'immer';
import Oas from 'oas';
import React, { useCallback, useMemo } from 'react';

import useValidateParameter from '@core/hooks/useValidateParameter';

import APISectionHeader from '@ui/API/SectionHeader';
import TypeMenu from '@ui/APIDesigner/TypeMenu';
import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';

import apiDesignerClasses from '../style.module.scss';

import classes from './style.module.scss';

type PropertyType = Record<string, SchemaObject>;

interface SchemaTypes {
  add: () => void;
  allOtherParameters: string[];
  isTopLevel?: boolean;
  onChange: ($TSFixMe, string) => void;
  remove: (idx: string) => void;
  schema: {
    default?: string;
    description?: string;
    format?: SchemaFormat;
    isRequired?: boolean;
    name?: string;
    properties?: PropertyType;
    required?: string[];
    type: SchemaType;
    'x-readme-id': string;
  };
}

const Schema = ({ add, allOtherParameters, onChange, remove, schema, isTopLevel }: SchemaTypes) => {
  const { parameterName, error, updateName } = useValidateParameter({
    allOtherParameters,
    id: schema['x-readme-id'],
    onChange,
    schema,
  });

  const updateValue = useCallback(
    (e, kind: string) => {
      const updatedSchema = { ...schema };
      if (kind === 'name') {
        updateName(e.currentTarget.value);
        return;
      }
      if (kind === 'description') updatedSchema.description = e.currentTarget.value;
      if (kind === 'required') updatedSchema.isRequired = !schema.isRequired;
      if (kind === 'default') updatedSchema.default = e.currentTarget.value;
      if (kind === 'type') {
        // Only objects have properties
        if (e.newType !== 'object') {
          delete updatedSchema.properties;
          delete updatedSchema.required;
        } else if (e.newType === 'object' && !updatedSchema.properties) {
          updatedSchema.properties = {};
          updatedSchema.required = [];
        }

        updatedSchema.type = e.newType.type;
        if (e.newType.format) {
          updatedSchema.format = e.newType.format;
        } else {
          delete updatedSchema.format;
        }
      }
      onChange(updatedSchema, schema['x-readme-id']);
    },
    [onChange, schema, updateName],
  );

  const handleChangeToOAS = useCallback(
    (editSchema, changedId) => {
      const currentSchema = Object.keys(schema.properties || {}).find(p => {
        return schema.properties![p]['x-readme-id'] === changedId;
      });

      // Item is being deleted
      if (!editSchema && currentSchema) {
        const newSchema = produce(schema, draft => {
          if (draft.properties) {
            delete draft.properties[currentSchema];
          }
        });
        onChange(newSchema, schema['x-readme-id']);
        return;
      }

      const newSchema = produce(schema, draft => {
        // I don't think this is possible
        // just making sure ts doesn't complain
        if (!draft.properties) return;
        if (currentSchema !== undefined && currentSchema !== editSchema.name) {
          delete draft.properties[currentSchema];
        }
        if (editSchema.isRequired) {
          if (draft.required && !draft.required.includes(editSchema.name)) {
            draft.required.push(editSchema.name);
          } else if (!draft.required) {
            // TODO: this code is really confusing as is. We are making sure if the array doesn't exist, we create it
            draft.required = [editSchema.name];
          }
        } else {
          draft.required = draft.required ? draft.required.filter(k => k !== editSchema.name) : [];
        }

        draft.properties[editSchema.name] = {
          type: editSchema.type,
          format: editSchema.format,
          default: editSchema.default,
          description: editSchema.description,
          properties: editSchema.properties,
          required: editSchema.required,
          // TODO: Ideally we wouldnd't want this to end up in the OAS file on save
          // https://linear.app/readme-io/issue/RM-11038/x-readme-id-shouldnt-be-saved-in-oas-file
          'x-readme-id': editSchema['x-readme-id'],
        };
      });
      onChange(newSchema, schema['x-readme-id']);
    },
    [onChange, schema],
  );

  let nestedSchemas;

  if (schema.type === 'object') {
    nestedSchemas = Object.keys(schema.properties || {})
      .sort((a, b) => {
        const sortIdA = schema.properties![a]['x-readme-id'].split('.')[1];
        const sortIdB = schema.properties![b]['x-readme-id'].split('.')[1];
        return sortIdA - sortIdB;
      })
      .map((propertyName, idx, allParameterNames) => {
        const allOtherParametersCurrent = allParameterNames.filter((_, i) => i !== idx);
        const property = schema.properties![propertyName];
        const addSchema = () => {
          // Want to make sure we are creating an id with the same level
          // So if the parent is 2.1, this will make the id 3.0 (assuming there are no other children at this level)
          const newId = `${parseInt(property['x-readme-id'].split('.')[0], 10) + 1}.${
            Object.keys(property.properties || []).length || 0
          }`;
          const schemaToAdd: SchemaTypes['schema'] = {
            name: propertyName,
            description: property.description,
            required: property.required as string[],
            isRequired: schema.required?.includes(propertyName),
            type: property.type as SchemaType,
            default: property.default,
            properties: {
              ...property.properties,
              'new-param': { type: 'string', 'x-readme-id': newId },
            },
            'x-readme-id': property['x-readme-id'],
          };
          handleChangeToOAS(schemaToAdd, newId);
        };

        const removeSchema = key => {
          handleChangeToOAS(undefined, key);
        };

        if (!property) return null;
        const s: SchemaTypes['schema'] = {
          name: propertyName,
          description: property.description,
          required: property.required as string[],
          isRequired: schema.required?.includes(propertyName),
          type: property.type as SchemaType,
          format: property.format as SchemaFormat,
          default: property.default,
          properties: property.properties as PropertyType,
          'x-readme-id': property['x-readme-id'],
        };
        return (
          <Schema
            key={idx}
            add={addSchema}
            allOtherParameters={allOtherParametersCurrent}
            onChange={handleChangeToOAS}
            remove={removeSchema}
            schema={s}
          />
        );
      });
  }

  return (
    <Flex align="stretch" className={classes.RequestBodyEditor} gap="0" justify="start" layout="col" tag="form">
      <Flex align="stretch" gap="0" layout="col">
        <Flex align="stretch" className={apiDesignerClasses['Parameter-group']} gap="0">
          <Flex align="baseline" gap="2px" justify="start">
            {!isTopLevel && (
              <input
                className={`${apiDesignerClasses['Parameter-input']} ${apiDesignerClasses['Parameter-input_name']}`}
                data-1p-ignore
                onChange={e => updateValue(e, 'name')}
                placeholder="Name"
                spellCheck="false"
                style={{ width: `${schema.name?.length || '7'}ch` }}
                value={parameterName}
              />
            )}

            <Dropdown justify="start">
              <span
                className={`${apiDesignerClasses['Parameter-input']} ${apiDesignerClasses['Parameter-input_type']}`}
              >
                {schema.format ? `${schema.type} (${schema.format})` : schema.type}
                {!isTopLevel && <Icon name="chevron-down" />}
              </span>
              {/* TODO: You should be able to set primitive types at the top level */}
              {/* https://linear.app/readme-io/issue/RM-9361/initially-creating-request-bodies */}
              {!isTopLevel && (
                <TypeMenu
                  format={schema.format}
                  setNewType={newType => updateValue({ newType }, 'type')}
                  shouldShowAllowObject={true}
                  type={schema.type || 'string'}
                />
              )}
            </Dropdown>

            {!isTopLevel && (
              <Flex
                className={`${apiDesignerClasses['Parameter-input']} ${
                  apiDesignerClasses['Parameter-input_required']
                } ${schema.isRequired ? apiDesignerClasses['Parameter-input_required_checked'] : ''}`}
                gap="xs"
                tag="label"
              >
                <span>required</span>
                <input
                  checked={schema.isRequired}
                  className={apiDesignerClasses['Parameter-input-checkbox']}
                  onChange={e => updateValue(e, 'required')}
                  type="checkbox"
                />
              </Flex>
            )}
          </Flex>
          <Flex align="center" gap="2px">
            {schema.type !== 'object' && (
              <input
                className={`${apiDesignerClasses['Parameter-input']} ${apiDesignerClasses['Parameter-input_form']}`}
                name="default"
                onChange={e => updateValue(e, 'default')}
                placeholder="Default Value"
                value={schema.default}
              />
            )}
            <Button
              className={apiDesignerClasses['Parameter-delete']}
              ghost
              kind="destructive"
              onClick={() => remove(schema['x-readme-id'])}
              size="xs"
            >
              <Icon name="trash" />
            </Button>
            {/* TODO: I don't like this placement, but fine for now */}
            {/* https://linear.app/readme-io/issue/RM-9349/cleanup-code-and-ts */}
            {schema.type === 'object' && (
              <Button kind="secondary" onClick={add} outline size="xs">
                <Icon name="plus" />
              </Button>
            )}
          </Flex>
        </Flex>
        {!isTopLevel && (
          <input
            className={apiDesignerClasses['Parameter-description']}
            onChange={e => updateValue(e, 'description')}
            placeholder="Description"
            value={schema.description || ''}
          />
        )}
        {error ? <span className={apiDesignerClasses['Parameter-error']}>{error}</span> : null}
      </Flex>
      {nestedSchemas}
    </Flex>
  );
};

interface RequestBodyEditorProps {
  api: EndpointDataType;
  updateAPI: (apiObject: EndpointDataType) => void;
}

const RequestBodyEditor = (props: RequestBodyEditorProps) => {
  const { api, updateAPI } = props;
  const oas = new Oas(api.schema);
  const operation = oas.operation(api.path, api.method);

  // TODO: should check if it's a ref here
  // https://linear.app/readme-io/issue/RM-8850/object-definitionsrefs

  // TODO: this really only works if the request body is an object
  // and doesn't really handle other types of request bodies
  // https://linear.app/readme-io/issue/RM-9361/initially-creating-request-bodies
  const requestBody = operation?.schema?.requestBody as RequestBodyObject;

  const schema = useMemo(
    () =>
      requestBody?.content?.['application/json']?.schema
        ? requestBody.content['application/json'].schema
        : { properties: {}, type: 'object', required: [] },
    [requestBody?.content],
  );

  const addIdToProperties = useCallback((newSchema: SchemaObject): SchemaTypes['schema'] => {
    const withIds = produce(newSchema, draftSchema => {
      const helper = (s, nestedLevel) => {
        if (s.properties) {
          let counter = 0;
          Object.keys(s.properties).forEach(key => {
            s.properties[key]['x-readme-id'] = s.properties[key]['x-readme-id'] || `${nestedLevel}.${counter}`;
            counter += 1;
            helper(s.properties[key], nestedLevel + 1);
          });
        }
      };
      helper(draftSchema, 0);
    });

    // The recursiveness + produce makes the type here get real wonky
    return withIds as SchemaTypes['schema'];
  }, []);

  const schemaWithIds = addIdToProperties(schema as SchemaObject);

  const updateTopLevelOAS = useCallback(
    newSchema => {
      const newOas = produce(api.schema, draft => {
        if (draft.paths && draft.paths[api.path]) {
          // Even when I explictly check for the existence of the path, TS still complains
          // @ts-ignore TODO: this doesn't work with non-application/json request bodies https://linear.app/readme-io/issue/RM-9573/support-for-request-content-types-other-than-applicationjson
          draft.paths![api.path]![api.method]!.requestBody!.content['application/json'].schema = newSchema;
        }
      });
      updateAPI({ ...api, schema: newOas });
    },
    [api, updateAPI],
  );

  const addSchema = useCallback(() => {
    const newSchemas = produce(schemaWithIds, draft => {
      if (!draft.properties) draft.properties = {};
      const newId = `0.${Object.keys(draft.properties).length || 0}`;
      // TODO: At this point we know it should exist
      // I just don't know how to tell ts that
      // @ts-ignore
      draft.properties[''] = { type: 'string', 'x-readme-id': newId };
    });
    updateTopLevelOAS(newSchemas);
  }, [schemaWithIds, updateTopLevelOAS]);

  const removeSchema = useCallback(
    key => {
      const modifiedSchemas = produce(schema, draft => {
        // TODO: At this point we know it should exist
        // I just don't know how to tell ts that
        // @ts-ignore
        delete draft.properties[key];
      });
      const newOas = produce(api.schema, draft => {
        if (draft.paths && draft.paths[api.path]) {
          // @ts-ignore TODO: this doesn't work with non-application/json request bodies https://linear.app/readme-io/issue/RM-9573/support-for-request-content-types-other-than-applicationjson
          draft.paths![api.path]![api.method]!.requestBody!.content['application/json'].schema = modifiedSchemas;
        }
      });
      updateAPI({ ...api, schema: newOas });
    },
    [api, updateAPI, schema],
  );

  // TODO: This doesn't allow for initially empty request bodies
  // We should probably have UI for adding a new schema
  // https://linear.app/readme-io/issue/RM-9361/initially-creating-request-bodies
  if (!operation.schema.requestBody) return null;

  return (
    <section>
      <APISectionHeader heading={'Request Body'} />

      <div className={classes.RequestBodyEditor}>
        <Schema
          add={addSchema}
          allOtherParameters={Object.keys(schemaWithIds.properties || {})}
          isTopLevel={true}
          onChange={updateTopLevelOAS}
          remove={removeSchema}
          schema={schemaWithIds}
        />
      </div>
    </section>
  );
};

export default RequestBodyEditor;

import type { ReadReferenceType } from '@readme/api/src/mappings/page/reference/types';

import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';

import Alert from '@ui/Alert';
import SmartLink from '@ui/SmartLink';
import Tooltip from '@ui/Tooltip';

import styles from './index.module.scss';

interface EndpointBannerProps {
  /**
   * The currently ingested source of API Definition.
   */
  source: ReadReferenceType['data']['api']['source'];
}

const EndpointBanner = ({ source }: EndpointBannerProps) => {
  const bem = useClassy(styles, 'EndpointBanner');

  const copy = useMemo(() => {
    switch (source) {
      case 'api':
      case 'form':
      case 'url':
        return {
          icon: 'oas',
          title: (
            <>
              This page is sourced from an{' '}
              <Link className={bem('-link')} to="/update/reference">
                OpenAPI definition
              </Link>
            </>
          ),
          subtitle: (
            <>
              You can make changes to the body content below, however all other changes must be made in your OpenAPI
              definition and then{' '}
              <Tooltip
                arrow={false}
                content={
                  <>
                    Certain incoming synced metadata (<code>title</code>, <code>excerpt</code>, <code>deprecated</code>{' '}
                    and <code>api</code>) will always overwrite any manual updates made from the ReadMe side.
                  </>
                }
              >
                <span className={bem('-tooltip')}>re-synced</span>
              </Tooltip>
              .
            </>
          ),
        };
      case 'bidi':
        return {
          icon: 'git-merge',
          title: 'This page is sourced from a Git respository.',
          subtitle: 'Changes will be bidirectionally synced with your Git repository.',
        };
      default:
        return {
          icon: 'compass',
          title: 'Welcome to the new experience.',
          subtitle: (
            <>
              To continue syncing your API please set up a connection with{' '}
              <SmartLink
                className={bem('-link')}
                onClick={e => {
                  e.preventDefault();
                  window.location.hash = '/configure/git-connection/github';
                }}
                to={'#/configure/git-connection/github'}
              >
                GitHub
              </SmartLink>{' '}
              or continue to{' '}
              <Link className={bem('-link')} to="/update/reference">
                upload
              </Link>{' '}
              with file or url
            </>
          ),
        };
    }
  }, [bem, source]);

  if (!copy) {
    return null;
  }

  const { icon, title, subtitle } = copy;

  return <Alert icon={icon} subtitle={subtitle} title={title} type="success" />;
};

export default EndpointBanner;

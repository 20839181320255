import type { OpenAPIV3 } from 'openapi-types';

import React from 'react';

import Icon from '@ui/Icon';
import Menu, { MenuDivider, MenuHeader, MenuItem } from '@ui/Menu';

export type SchemaType = 'array' | 'boolean' | 'integer' | 'number' | 'object' | 'string';
export type SchemaFormat =
  | 'binary'
  | 'byte'
  | 'date-time'
  | 'date'
  | 'double'
  | 'float'
  | 'int32'
  | 'int64'
  | 'password';

const basicTypes = [
  {
    icon: 'string',
    type: 'string' as SchemaType,
  },
  {
    icon: 'integer',
    type: 'integer' as SchemaType,
  },
  {
    icon: 'binary',
    type: 'boolean' as SchemaType,
  },
];

const numberTypes = [
  {
    icon: 'life-buoy',
    type: 'number' as SchemaType,
    format: 'float' as SchemaFormat,
  },
  {
    icon: 'double',
    type: 'number' as SchemaType,
    format: 'double' as SchemaFormat,
  },
  {
    icon: '',
    type: 'integer' as SchemaType,
    format: 'int32' as SchemaFormat,
  },
  {
    icon: '',
    type: 'integer' as SchemaType,
    format: 'int64' as SchemaFormat,
  },
];

const stringTypes = [
  {
    icon: 'calendar',
    type: 'string' as SchemaType,
    format: 'date' as SchemaFormat,
  },
  {
    icon: 'clock',
    type: 'string' as SchemaType,
    format: 'date-time' as SchemaFormat,
  },
  {
    icon: 'asterisk',
    type: 'string' as SchemaType,
    format: 'password' as SchemaFormat,
  },
  {
    type: 'string' as SchemaType,
    format: 'byte' as SchemaFormat,
  },
  {
    icon: 'binary',
    type: 'string' as SchemaType,
    format: 'binary' as SchemaFormat,
  },
];

interface TypeMenuProps {
  format?: SchemaFormat;
  items?: OpenAPIV3.ArraySchemaObject['items'] & OpenAPIV3.SchemaObject;
  setNewType: ({
    format,
    items,
    type,
  }: {
    format?: SchemaFormat;
    // Left this type simple since getting it with the OpenAPI types is a bit complex
    items?: { format?: SchemaFormat; type: SchemaType };
    type: SchemaType;
  }) => void;
  shouldShowAllowObject: boolean;
  type: SchemaType;
}

/**
 * Sets the correct type/format for the selected type. For simplicity we
 * combined them into one menu as opposed to making it two different things that
 * user sets.
 */
const TypeMenu = ({ setNewType, type, format, items, shouldShowAllowObject }: TypeMenuProps) => {
  return (
    <Menu>
      {basicTypes.map(t => (
        <MenuItem
          key={t.type}
          active={type === t.type && !format}
          icon={<Icon name={t.icon || 'blank'} />}
          onClick={() => setNewType({ type: t.type })}
        >
          {t.type}
        </MenuItem>
      ))}
      <MenuItem
        active={type === 'array' && !format}
        icon={<Icon name="brackets" />}
        onClick={() => setNewType({ type: 'array' })}
      >
        <span>array</span>
        <Menu>
          {basicTypes.map(t => (
            <MenuItem
              key={t.type}
              active={type === 'array' && !!items && items.type === t.type && !items.format}
              icon={<Icon name={t.icon || 'blank'} />}
              onClick={() => setNewType({ type: 'array', items: { type: t.type } })}
            >
              {t.type}
            </MenuItem>
          ))}
          <MenuDivider />
          <MenuHeader>Number Format</MenuHeader>
          {numberTypes.map(t => (
            <MenuItem
              key={t.format}
              active={type === 'array' && !!items && items?.type === t.type && items?.format === t.format}
              icon={<Icon name={t.icon || 'blank'} />}
              onClick={() =>
                setNewType({
                  type: 'array',
                  items: { type: t.type, format: t.format },
                })
              }
            >
              {t.format}
            </MenuItem>
          ))}
          <MenuDivider />
          <MenuHeader>String Format</MenuHeader>
          {stringTypes.map(t => (
            <MenuItem
              key={t.format}
              active={type === 'array' && !!items && items?.type === t.type && items?.format === t.format}
              icon={<Icon name={t.icon || 'blank'} />}
              onClick={() =>
                setNewType({
                  type: 'array',
                  items: { type: t.type, format: t.format },
                })
              }
            >
              {t.format}
            </MenuItem>
          ))}
        </Menu>
      </MenuItem>
      {!!shouldShowAllowObject && (
        <MenuItem
          active={type === 'object' && !format}
          icon={<Icon name="json" />}
          onClick={() => setNewType({ type: 'object' })}
        >
          object
        </MenuItem>
      )}
      <MenuDivider />
      <MenuHeader>Number Format</MenuHeader>
      {numberTypes.map(t => (
        <MenuItem
          key={t.format}
          active={type === t.type && format === t.format}
          icon={<Icon name={t.icon || 'blank'} />}
          onClick={() => setNewType({ type: t.type, format: t.format })}
        >
          {t.format}
        </MenuItem>
      ))}
      <MenuDivider />
      <MenuHeader>String Format</MenuHeader>
      {stringTypes.map(t => (
        <MenuItem
          key={t.format}
          active={type === t.type && format === t.format}
          icon={<Icon name={t.icon || 'blank'} />}
          onClick={() => setNewType({ type: t.type, format: t.format })}
        >
          {t.format}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default TypeMenu;

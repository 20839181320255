import React from 'react';

import useClassy from '@core/hooks/useClassy';

import PageNavItemErrorBadge from '@ui/Dash/PageNav/Item/ErrorBadge';
import Flex from '@ui/Flex';

import styles from './ErrorStatus.module.scss';

export function SidebarNavErrorStatus({ totalUnrenderable }: { totalUnrenderable: number }) {
  const bem = useClassy(styles, 'SidebarNavErrorStatus');

  return totalUnrenderable ? (
    <Flex align="center" className={`${bem()} SidebarNavErrorStatus`} gap="xs" justify="start">
      <PageNavItemErrorBadge />
      Pages with invalid MDX ({totalUnrenderable})
    </Flex>
  ) : null;
}

export default SidebarNavErrorStatus;
